.grantThorntonTheme {
  --five-f-link-color: rgb(114,87,153);
  --five-f-link-color-hover: rgb(155, 215, 50);

  --five-f-action-color: rgb(114,87,153);
  --five-f-indicator-background-color-unread: rgb(114,87,153);

  --five-f-filter-beta-tag-background-color: rgb(114,87,153);
  --five-f-filter-badge-background-color: rgb(114,87,153);

  --five-f-filter-button-background-color-active: rgb(114,87,153);

  // Button color hover override
  --five-f-icon-button-color-hover: rgb(114,87,153);

  // General overrides (order important)
  // 1. Base overrides
  // 2. Functionality overrides
  // 3. Module dependent overrides.
  @import "grant_thornton/colors";
  @import "grant_thornton/font";
  @import "grant_thornton/avatar";
  @import "grant_thornton/atag_link_nav";
  @import "grant_thornton/button";
  @import "grant_thornton/card";
  @import "grant_thornton/checkbox";
  @import "grant_thornton/dialog";
  @import "grant_thornton/progress_bar";
  @import "grant_thornton/radio_button";
  @import "grant_thornton/table";
  @import "grant_thornton/tabs";
  @import "grant_thornton/stepper";

  // Special functionalities
  @import "grant_thornton/alert";
  @import "grant_thornton/dms_selection_folder_tree";
  @import "grant_thornton/dropdown_menu";
  @import "grant_thornton/listing_box";
  @import "grant_thornton/tags_labels";
  @import "grant_thornton/person-selector";
  @import "grant_thornton/upload";
  @import "grant_thornton/sidebar";

  // View/Module overrides
  @import "grant_thornton/collecto";
  @import "grant_thornton/signature";
  @import "grant_thornton/dashboard_calendar";
  @import "grant_thornton/page-header";

  .fivef-gt-sync-dialog .mat-progress-spinner circle, .mat-spinner circle {
    stroke: #FFFFFF;
  }
}

$content-indent: 120px;
$text-color: $dvtx-fivef-blue-500;

html,
body {
  height: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  background-color: $dvtx-cool-grey-25;
  color: $text-color;
  min-width: 320px;
  overflow-anchor: none;
}

#wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.wrapper {
  padding-top: 75px;
  height: 100%;

  // @include mq-xl-down {
  //   padding-top: 75px;
  // }

  // @include mq-lg-down {
  //   padding-top: 61px;
  // }

  // @include mq-md-down {
  //   padding-top: 61px;
  // }

  // @include mq-sm-down {
  //   padding-top: 61px;
  // }
  // @include mq-lg-up {
  //   padding-top: 75px;
  // }
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-20 {
  margin: 0 20px;
}

.quickstart {
  position: fixed;
  top: 100px;
  right: 40px;
  z-index: map-get($z-index-set, z-quickstart);

  @include mq-lg-down {
    top: 100px;
  }

  @include mq-md-down {
    right: 20px;
  }

  @include mq-sm-down {
    bottom: initial;
    right: 15px;

    .quickstart-list-holder {
      display: flex;

      .quickstart-btn {
        margin-left: 0.5rem;
      }
    }
  }

  .quickstart-list {
    //@include mq-sm-down {
    //  display: none;
    //}
  }

  .quickstart-mob-icon {
    //@include mq-md-up {
    //  display: none;
    //}
  }
}

.container {
  min-height: 100%;
  padding: ($content-indent * 0.5);
  overflow: hidden;
  @include mq-sm-down {
    padding: $content-indent * 0.125;
  }

  @include mq-sm-up {
    padding: $content-indent * 0.25;
  }

  @include mq-md-up {
    padding: $content-indent * 0.5;
  }
}

.container {
  width: 100%;
  max-width: 1200px;
}

.md-container {
  max-width: 960px;
  margin: 0 auto;
}

.content {
  max-width: 960px;
  margin: 0 auto;

  @include mq-sm-up {
    padding-top: 80px;
  }

  @include mq-md-up {
    padding-top: 50px;
  }

  @media (min-width: 1441px) {
    padding-left: 350px;
    padding-top: 0;
    max-width: calc(960px + 400px);
  }
}

// Repeatable patterns
.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.pointer, .dvtx-pointer {
  cursor: pointer;
}

.border-t {
  border-top: 1px solid $dvtx-fivef-blue-100;
}

.border-b {
  border-bottom: 1px solid $dvtx-fivef-blue-100;
}

ol {
  padding-left: $content-gap*2;

  @include mq-sm-down {
    padding-left: $content-gap;
  }

  li + li {
    margin-top: 5px;
  }

  &.alphabet-list {
    list-style-type: lower-alpha;
  }

  &.demical {
    list-style-type: decimal;
  }

  &.disc-list {
    list-style: disc;
  }

  &.number-list {
    list-style-type: none;

    & > li {
      position: relative;

      &:before {
        content: attr(data-number);
        font-weight: bold;
        display: inline-block;
        width: 1.5rem;
        position: absolute;
        left: -$content-gap*2;

        @include mq-sm-down {
          left: -25px;
        }
      }
    }

    &.normal {
      & > li {
        &:before {
          font-weight: normal;
        }
      }
    }
  }
}

@keyframes dvtx-dvtx-loading-indicator-blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

.dvtx-loading-indicator span {
  animation-name: dvtx-dvtx-loading-indicator-blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.dvtx-loading-indicator span:nth-child(2) {
  animation-delay: .2s;
}

.dvtx-loading-indicator span:nth-child(3) {
  animation-delay: .4s;
}

.dvtx-inline-valign {
  display: inline-flex;
  vertical-align: middle;
}
.customColorPicker {
  position: absolute !important;
  visibility: hidden !important;
}

.five-f-settings-container {
  width: 100% !important;
  margin-top: 1.5rem !important;
  margin-left: 1rem !important;

  &.five-f-block {
    display: block;
  }

  &.five-f-settings-pull-up {
    // If containers already implements a right aligned search bar,
    // it can be pulled up into the nav bar. This is just a
    // work arround for a general concept later.
    // This is a marker class as well to find these acandidates later.
    margin-top: -40px !important;
  }
}

.five-f-details-content-container {
  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__headline {
    margin: 10px 45px 10px 30px;
  }

  &__list-item__property-container {
    display: flex;
    flex: 0 0 170px;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  &__list-item__property {
    width: 170px;

    &__col-3 {
      width: 234px !important;
    }

    &__col-2 {
      width: 50% !important;
    }

    &__col-1 {
      width: 100% !important;
    }
  }

  label {
    color: rgba(0, 0, 0, 0.54)
  }

  &__element-nav-btn {
    margin-left: auto;
    min-width: 100px;
    display: flex;
    justify-content: end;
  }

  &__headline-title {
    // margin: 5px 45px;
  }

  &__headline-group-title {
    // margin: 10px 45px;
  }

  &__headline-border {
    border-bottom: 2px inset;
  }

  .mat-icon-button ::ng-deep .mat-button-focus-overlay {
    display: none;
  }
}

/**
 * 1/3 relative space of outer container. Analogous to w-100.
 */
.w-33 {
  width: 33% !important;
}

/**
 * 1/2 relative space of outer container. Analogous to w-100.
 */
.w-50 {
  width: 50% !important;
}

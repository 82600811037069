/* Material form override.
 *
 * Author: Abdallah Salem <a.salem@5fsoftware.de>
 * @import "./src/assets/styles/override/mat_form";
 */

@import "../utils/var";
@import "../utils/mixins";

.fivef-gt-sync-dialog--select-option .mat-option-text,
.fivef-gt-sync-dialog .mat-select-value {
  line-height: 1.1em;
}

.mat-checkbox {
  display: unset !important;
  &-disabled {
    opacity: 0.6;
  }
}

.five-f-select-field {
  @extend .five-f-elevation-z-1 !optional;
  margin: 0;
  padding: 0 1rem;
  border: 1px solid $dvtx-cool-grey-200;
  border-radius: 4px;
  background: white;

  .mat-select-value,
  .mat-select-placeholder,
  .mat-select-value-text {
    color: $dvtx-fivef-blue-500;
  }
}

.five-f-input-field {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
    .mat-form-field-flex {
      align-items: center;

      .mat-form-field-infix {
        border: 0 !important;
        padding: 0 !important;
        .mat-input-element {
          height: 46px;
        }
      }

      .mat-form-field-prefix {
        height: 46px;
        display: inline-flex;
        align-items: center;
        line-height: 1;
        line-height: 100%;
        margin-right: 6px;
      }
    }

    .mat-form-field-label-wrapper {
      padding: 0 !important;
      top: 0 !important;
      overflow: visible !important;
      label {
        margin: 0;
        display: flex;
        align-items: center;
        top: 0;
        bottom: 0;
      }
    }

    .mat-form-field-underline {
      bottom: 0 !important;
    }

    .mat-form-field-subscript-wrapper {
      top: 100% !important;
    }
  }
}


.customLeftSiedBar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100% - 10vh);
  padding-top: 8px;
}

#first-sidebar-container {
  z-index: 1030;
  position: relative;
  width: 70px;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  min-width: 70px;
  max-width: 70px;
}

#first-sidebar-container {
  background: #fff;
  border-right: 1px solid rgba(0, 40, 100, 0.12)
}

::ng-deep .dvtx-active-sidebar-link {
  // letter-spacing: 1px;
  color: $dvtx-fivef-blue-500;
  border-color: $dvtx-fivef-blue-400;
  background-color: $dvtx-fivef-blue-25;
}

#first-sidebar-container .header-brand {
  padding: 0;
  transition: .3s opacity;
  margin-top: 5px;
  margin-bottom: 10px;
  display: inline-block
}

@media screen and (max-width: 640px) {
  #first-sidebar-container .header-brand {
    margin: 0 15px 0 0
  }
}

#first-sidebar-container .header-brand:hover {
  opacity: .8;
  color: inherit;
  text-decoration: none
}

#first-sidebar-container .brand-logo {
  background: #292b30;
  padding: 10px;
  color: #fff;
  border-radius: 35px;
  display: inline-block
}

#first-sidebar-container .nav-link {
  padding: 12px;
  display: inline-flex;
}

#first-sidebar-container .nav-link i {
  font-size: 20px
}

#first-sidebar-container .nav-link .avatar {
  border: 2px solid #fff
}

#first-sidebar-container .header-avatar {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: inline-block;
  vertical-align: bottom
}

#first-sidebar-container .hright-sidebar .dropdown-sidebar-menu {
  top: 50px !important;
  left: 50px !important
}

@media screen and (max-width: 767px) {
  #first-sidebar-container .hright-sidebar .dropdown-sidebar-menu {
    top: 0 !important;
    left: auto !important
  }
}


.five-f-menu-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none
}


.five-f-menu-sidebar > li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative
}

.five-f-menu-sidebar > li i {
  font-size: 16px;
  width: 30px;
  display: inline-block;
  position: relative;
  top: 1px
}

.five-f-menu-sidebar a {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  vertical-align: middle;
  zoom: 1;
  display: block;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-right: 3.5px solid transparent;
  color: #292b30;
  position: relative;
  padding: 10px 0;
  outline-width: 0
}

.five-f-menu-sidebar .dvtx-active-sidebar-link > a {
  font-weight: 600;
  color: #292b30;
  border-color: #292b30
}

.five-f-menu-sidebar .dvtx-active-sidebar-link ul .dvtx-active-sidebar-link a {
  color: #292b30;
  background: transparent
}

.five-f-menu-sidebar ul {
  padding: 0;
  list-style: none
}

.five-f-menu-sidebar ul > li:last-child {
  padding-bottom: 20px
}

.five-f-menu-sidebar ul a {
  color: #4D5052;
  font-size: 14px;
  padding: 5px 15px 5px 30px;
  position: relative
}

.five-f-menu-sidebar a {
  color: #202121;
}

.five-f-menu-sidebar .collapseBtn a {
  color: #7b8490 !important;

}

.five-f-menu-sidebar .collapseBtn a:hover {
  color: $dvtx-fivef-blue-500 !important;
  border-color: none !important;
}

.five-f-menu-sidebar a:hover {
  // background-color: $dvtx-fivef-blue-25;
  color: $dvtx-fivef-blue-500 !important;
  border-color: $dvtx-fivef-blue-500;
}

.five-f-menu-sidebar .active > a {
  // background-color: $dvtx-fivef-blue-25;
  color: $dvtx-fivef-blue-500;
  border-color: $dvtx-fivef-blue-400;
}

.five-f-menu-sidebar .active ul .active a {
  color: $dvtx-fivef-blue-500;
  background: transparent;
}

.five-f-menu-sidebar > li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.five-f-menu-sidebar .active > a {
  color: $dvtx-fivef-blue-500;
  border-color: $dvtx-fivef-blue-400;
}

.five-f-menu-sidebar .active > a {
  font-weight: 600;
  color: #292b30;
  border-color: #292b30;
}

.five-f-menu-sidebar > li i {
  font-size: 16px;
  width: 30px;
  display: inline-block;
  position: relative;
  top: 1px;
}
